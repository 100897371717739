// 日期处理，此处引入了jquery，方便同步请求。

export function DateFormat(datatime, formatStr) {
  var date;
  if (datatime instanceof Date) {
      date = datatime;
  } else {
      if (!datatime || datatime == "undefine") {
          return "";
      }
      datatime = datatime.replace(/-/g, "/");
      date = new Date(datatime);
  }
  var zeroize = function (value, length) {
      if (!length) {
          length = 2;
      }
      value = new String(value);
      for (var i = 0, zeros = ""; i < length - value.length; i++) {
          zeros += "0";
      }
      return zeros + value;
  };
  return formatStr.replace(/"[^"]*"|'[^']*'|\b(?:d{1,4}|M{1,4}|yy(?:yy)?|([hHmstT])\1?|[lLZ])\b/g, function ($0) {
      switch ($0) {
          case "d":
              return date.getDate();

          case "dd":
              return zeroize(date.getDate());

          case "ddd":
              return ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"][date.getDay()];

          case "dddd":
              return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][date.getDay()];

          case "M":
              return date.getMonth() + 1;

          case "MM":
              return zeroize(date.getMonth() + 1);

          case "MMM":
              return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][date.getMonth()];

          case "MMMM":
              return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][date.getMonth()];

          case "yy":
              return new String(date.getFullYear()).substr(2);

          case "yyyy":
              return date.getFullYear();

          case "h":
              return date.getHours() % 12 || 12;

          case "hh":
              return zeroize(date.getHours() % 12 || 12);

          case "H":
              return date.getHours();

          case "HH":
              return zeroize(date.getHours());

          case "m":
              return date.getMinutes();

          case "mm":
              return zeroize(date.getMinutes());

          case "s":
              return date.getSeconds();

          case "ss":
              return zeroize(date.getSeconds());

          case "l":
              return date.getMilliseconds();

          case "ll":
              return zeroize(date.getMilliseconds());

          case "tt":
              return date.getHours() < 12 ? "am" : "pm";

          case "TT":
              return date.getHours() < 12 ? "AM" : "PM";
      }
  });
}
