<template>
  <div class="app-container" v-loading="loading"  element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <el-header class="new-header">
      <div class="new-header-left">
        <span>导入客户</span>
        <span class="back" @click="handleReturn">返回</span>
      </div>
    </el-header>
    <el-main style="background-color: #fff">
      <div class="upload-container">
        <template>
          <!--上传文件模块-->
          <div style="display: flex;align-items: center">
            <el-upload class="upload-demo" :show-file-list=false action="" drag :on-change="acceptFile"
                       :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传。</em></div>
            </el-upload>
            <div style="margin-left: 5px;">
              <div class="upload-text">注意事项:<a
                  href="https://www.hukebao.top/download/import_customer_template_tong.xlsx">点击下载导入数据模板</a>
              </div>
              <div class="upload-text"> 1）模板中的表头不可更改，表头行不可删除</div>
              <div class="upload-text"> 2）单次导入的数据不超过1万条</div>
              <div class="upload-text"> 3）公司名称和姓名最长支持63个汉字</div>
              <div class="upload-text"> 4）地址最长支持128个汉字</div>
            </div>
          </div>
        </template>
        <template>
          <el-form ref="importBatch" :inline="true" :model="importFrom" style="margin-top: 20px;margin-left: 10px;">
            <el-form-item label="导入批次：">
              <el-input
                  v-model="importFrom.importBatch"
                  placeholder="请输入导入的批次信息"
                  size="small"
                  clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号：">
              <el-switch
                  v-model="importFrom.duplicateStatus"
                  @change="changeNextInfo"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="可以重复"
                  inactive-text="不能重复">
              </el-switch>
            </el-form-item>
          </el-form>
          <el-form :inline="true" :model="searchForm" ref="searchForm" @keyup.enter.native="onSubmit" style="margin-left: 10px;">
            <el-form-item label="手机号:" prop="customerPhone">
              <el-input
                  v-model="searchForm.customerPhone"
                  placeholder="手机号"
                  size="small"
                  clearable
                  @clear="clearSearchForm"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="searchFormOnSubmit" icon="search" size="small">
                搜索
              </el-button>
            </el-form-item>
          </el-form>
        </template>
        <template>
        </template>
        <template>
          <el-table :data="importShowIntable" border>
            <el-table-column label="公司名称" align="center" prop="companyName"></el-table-column>
            <el-table-column label="姓名" align="center" prop="customerName"></el-table-column>
            <el-table-column label="手机号" align="center" prop="customerPhone">
              <template slot-scope="scope">
                <el-input
                    v-model="scope.row.customerPhone"
                    size="small"
                />
              </template>
            </el-table-column>
            <el-table-column label="地址" align="center" prop="address"></el-table-column>
            <el-table-column label="行业" align="center" prop="industry"></el-table-column>
            <el-table-column label="备注" align="center" prop="remark"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="danger" @click="handleRemoveCustomerItem(scope.row)" size="small">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <el-row type="flex" justify="flex-end">
          <el-col>
            <div class="pagination-block">
              <el-pagination
                  @size-change="handleTableSizeChange"
                  @current-change="handleTableCurrentChange"
                  :current-page="tablePageVo.page"
                  :page-sizes="[10, 20,50]"
                  :page-size="tablePageVo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="this.listTable.length">
              </el-pagination>
            </div>
          </el-col>
        </el-row>
        <div class="statistics">
          <span>总计:{{ this.listTable.length }}</span>
        </div>
      </div>
      <el-row type="flex" justify="center">
        <el-col :span="4">
          <el-button type="primary" @click="handleCreateListCustomerRequest">{{ nextInfo }}</el-button>
        </el-col>
      </el-row>
    </el-main>

    <el-dialog title="手机号重复数据"
               :visible.sync="dialogForDuplicateData">
      <el-table
          :data="duplicateTableData"
          stripe
          border
          width="100%"
          height="300"
          v-loading="duLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
      >
        <el-table-column
            prop="companyName"
            label="公司名称"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="customerName"
            label="姓名"
            align="center"
        ></el-table-column>
        <el-table-column
            prop="customerPhone"
            label="手机号"
            align="center"
        >
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="flex-end">
        <el-col>
          <div class="pagination-block">
            <el-pagination
                @size-change="handleDuplicateTableSizeChange"
                @current-change="handleDuplicateTableCurrentChange"
                :current-page="duplicatePageVo.page"
                :page-sizes="[10, 20,50]"
                :page-size="duplicatePageVo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="this.duplicateData.length">
            </el-pagination>
          </div>
        </el-col>
      </el-row>
      <div class="statistics">
        <span>总计:{{ this.duplicateData.length }}</span>
      </div>
      <div slot="footer">
        <el-button type="primary" plain @click="dialogForDuplicateData = false"
        >取消</el-button
        >
        <el-button type="primary" @click="handleSaveInputCustomer"
        >继续导入</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from "xlsx";
import {throttle} from "../../utils/tools";
import {batchImportCustomerList, batchImportCustomerRepeatList} from "../../api";
import {DateFormat} from "../../utils/datetime";
import { Loading } from 'element-ui';
import fa from "element-ui/src/locale/lang/fa";

export default {
  name: "importCustomer",
  data() {
    return {
      nextInfo: "下一步",
      dialogForDuplicateData: false,
      duLoading: false,
      loading: false,
      form: {
        customerList: [],
      },
      duplicateData: [],
      duplicateTableData: [],
      listTable: [],
      copyListTable: [],
      tablePageVo: {
        page: 1,
        pageSize: 10
      },
      duplicatePageVo: {
        page: 1,
        pageSize: 10
      },
      importFrom: {
        importBatch: "",
        duplicateStatus: false, //true代表可以重复
      },
      searchForm: {
        customerPhone: ""
      }
    }
  },
  created() {
    this.importFrom.importBatch = DateFormat(new Date(), "yyyy年MM月dd日hh时mm分")
  },
  computed: {
    total() {
      return this.form.customerList.length
    },
    importShowIntable() {
      if (this.listTable.length <= this.tablePageVo.pageSize) {
        return this.listTable
      }
      let temp = this.tablePageVo.pageSize * this.tablePageVo.page
      if (temp - this.listTable.length >= this.tablePageVo.pageSize) {
        return this.listTable.slice((this.tablePageVo.page - 2) * this.tablePageVo.pageSize, this.tablePageVo.pageSize * this.tablePageVo.page)
      }
      return this.listTable.slice((this.tablePageVo.page - 1) * this.tablePageVo.pageSize, this.tablePageVo.pageSize * this.tablePageVo.page)
    }
  },
  methods: {
    clearSearchForm() {
      this.searchForm.customerPhone = null
      this.searchFormOnSubmit();
    },
    searchFormOnSubmit() {
      if (this.searchForm.customerPhone && this.searchForm.customerPhone.length > 0) {
        let customerPhone = this.searchForm.customerPhone
        this.listTable = this.copyListTable
        this.listTable = this.listTable.filter(item => {
          if (item.customerPhone && item.customerPhone.length > 0) {
            return item.customerPhone.indexOf(customerPhone) > -1
          }
          return false
        })
      } else {
        this.listTable = this.copyListTable
      }
    },
    changeNextInfo() {
      if (this.importFrom.duplicateStatus) {
        this.nextInfo = "保存";
      } else {
        this.nextInfo = "下一步";
      }
    },
    handleTableSizeChange(val) {
      this.tablePageVo.pageSize = val;
    },
    handleTableCurrentChange(val) {
      this.tablePageVo.page = val;
    },
    handleDuplicateTableSizeChange(val) {
      this.duplicatePageVo.pageSize = val;
      this.duplicateTableData = this.duplicateData.slice(
          (this.duplicatePageVo.page - 1) * this.duplicatePageVo.pageSize,
          this.duplicatePageVo.page * this.duplicatePageVo.pageSize
      );
    },
    handleDuplicateTableCurrentChange(val) {
      this.duplicatePageVo.page = val;
      this.duplicateTableData = this.duplicateData.slice(
          (this.duplicatePageVo.page - 1) * this.duplicatePageVo.pageSize,
          this.duplicatePageVo.page * this.duplicatePageVo.pageSize
      );
    },
    handleRemoveCustomerItem(row) {
      if (row) {
        this.listTable.splice(this.listTable.indexOf(row), 1)
        this.copyListTable.splice(this.copyListTable.indexOf(row), 1)
      }
    },
    handleEditCustomerItem(row) {
    },
    handleAddToList() {
      this.importDisabled = true
      this.importBtnText = '正在导入中...'
      let that = this;
      throttle(function () {
        that.form.customerList = [...that.form.customerList, ...that.listTable];
        //取消电话号码中的空格
        that.form.customerList = that.form.customerList.map(item => {
          let customerPhone = item.customerPhone.toString().replace(/\s/g, "")
          item.customerPhone = customerPhone
          return item
        })
        that.importDisabled = false
        that.importBtnText = '确认导入'
        that.dialogForImport = false
      }, 1000)()
    },
    acceptFile(f) {
      //获取到文件名最后一个"."的位置
      let extStart = f.name.lastIndexOf(".");
      //通过未知 获取文件后缀名
      let filetype = f.name.substring(extStart, f.name.length).toUpperCase();
      //如果后缀名不是 excel文件 提示上传指定文件
      if (filetype != ".XLSX" && filetype != ".XLS") {
        alert("请上传正确的文件格式");
        return;
      }
      //格式正确后 开始对文件进行操作
      this.handleFile(f);
    },
    handleFile(f) {
      //如果浏览器支持 创建fileReader
      const reader = new FileReader();
      if (window.FileReader) {
      } else {
        this.$message.error('你的浏览器不支持fileReader,请升级您的浏览器！');
      }
      reader.onload = (e) => {
        let data = e.target.result,
            //使用XLSL控件 读取文件信息
            workbook = XLSX.read(data, {type: 'binary'}),
            //获取到excel里第一个sheet文件
            sheetName = workbook.SheetNames[0],
            //获取到sheet内容
            sheet = workbook.Sheets[sheetName];
        //清空表格
        this.listTable = [];
        //开始获取excel内容
        this.getDate(sheet);
      };
      //将文件读取为二进制码
      reader.readAsBinaryString(f.raw);
    },
    getDate(sheet) {
      let colArr = [], //存放A~Z
          companyName = "",
          customerPhone = "",
          customerName = "",
          address = "",
          industry = "",
          remark = "";
      //将A~Z存放至数组中
      for (let col = 65; col <= 90; col++) {
        let c = String.fromCharCode(col);
        colArr.push(c);
      }
      //从A2遍历至Z2 当有一个 为空时 遍历停止
      for (var i = 0; ; i++) {
        if (sheet[colArr[i] + 1] == null) {
          break;
        }
        switch (sheet[colArr[i] + 1].v) {
          case "公司名称":
            companyName = colArr[i];
            break;
          case "姓名":
            customerName = colArr[i];
            break;
          case "手机号":
            customerPhone = colArr[i];
            break;
          case "地址":
            address = colArr[i];
            break;
          case "行业":
            industry = colArr[i];
            break;
          case "备注":
            remark = colArr[i];
            break;
          default:
            break;
        }
      }
      //开始遍历每一行 从第三列开始遍历
      for (var row = 2; ; row++) {
        let obj = {};
        //当某一行全部为空时 遍历停止
        if (sheet[companyName + row] == null && sheet[customerName + row] == null && sheet[customerPhone + row] == null && sheet[address + row] == null && sheet[industry + row] == null && sheet[remark + row] == null) {
          break;
        }
        if (sheet[companyName + row]) {
          obj.companyName = sheet[companyName + row].v;
        } else {
          obj.companyName = ''
        }
        if (sheet[customerName + row]) {
          obj.customerName = sheet[customerName + row].v;
        } else {
          obj.customerName = ''
        }
        if (sheet[customerPhone + row]) {
          obj.customerPhone = sheet[customerPhone + row].v;
        } else {
          obj.customerPhone = ''
        }
        if (sheet[address + row]) {
          obj.address = sheet[address + row].v;
        } else {
          obj.address = ''
        }
        if (sheet[industry + row]) {
          obj.industry = sheet[industry + row].v;
        } else {
          obj.industry = ''
        }
        if (sheet[remark + row]) {
          obj.remark = sheet[remark + row].v;
        } else {
          obj.remark = ''
        }
        this.listTable.push(obj);
      }

      this.copyListTable = this.listTable
    },
    handleReturn() {
      this.$router.go(-1)
    },
    handleCreateListCustomerRequest() {
      if (this.importFrom.duplicateStatus) {
        // 可以重复客户,直接调用创建客户接口
        this.handleSaveInputCustomer();
      } else {
        // 获取重复的客户数据
        if (this.handleVerifyData()) {
          this.dialogForDuplicateData = true;
          this.duLoading = true;
          let param = this.handleParam();
          batchImportCustomerRepeatList(param).then(data => {
            this.duLoading = false;
            if (data) {
              this.duplicateData = data
              this.duplicateTableData = this.duplicateData.slice(
                  (this.duplicatePageVo.page - 1) * this.duplicatePageVo.pageSize,
                  this.duplicatePageVo.page * this.duplicatePageVo.pageSize
              );
            }
          }).catch(() =>  {
            this.duLoading = false
            this.dialogForDuplicateData = false;
          })
        }
      }
    },
    handleSaveInputCustomer() {
      if (this.handleVerifyData()) {
        this.loading = true
        let param = this.handleParam();
        batchImportCustomerList(param).then(data => {
          if (data) {
            this.$notify({
              title: '成功',
              message: '批量添加客户成功',
              type: 'success'
            })
            //开启定时器
            let timerCount = 3
            this.timer = setInterval(() => {
              timerCount--
              if (timerCount === 0) {
                this.loading = false
                clearInterval(this.timer) // 清除定时器
              }
            }, 3000);
            this.loading = false
            this.$router.go(-1)
          }
        }).catch(() =>  {
          this.loading = false
          this.duLoading = false
        })
      }
    },
    handleVerifyData() {
      if (this.listTable.length === 0) {
        this.$message.error('导入的数据为空')
        return false;
      }
      let emptyItem = this.listTable.find(item => item.customerPhone === '')
      if (emptyItem) {
        console.log(emptyItem)
        this.$message.error('导入的客户手机号不能为空,请查证后重试')
        this.tablePageVo.page = Math.ceil(this.listTable.indexOf(emptyItem) / this.tablePageVo.pageSize)
        return false;
      }
      return true;
    },
    handleParam() {
      let param = {
        userId: this.$store.getters.userId,
        customerList: this.listTable,
        importBatch: this.importFrom.importBatch,
        duplicateStatus: this.importFrom.duplicateStatus
      }
      if (this.$route.query.from == 'myCustomer' || this.$route.query.from == 'taskCustomerList') {
        param.myCustomer = true
      }
      return param;
    }
  }
}
</script>

<style scoped lang="scss">
.upload-demo {
  margin-left: 120px;

}

.upload-text {
  font-size: 14px;
  color: #606266;

  a {
    color: #409eff;
  }
}

.back {
  font-size: 12px;
  cursor: pointer;
  color: rgb(64, 158, 255);
  margin-left: 4px;
}
</style>
